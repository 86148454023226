<template>
	<section class="gardenManagementPage">
		<!-- <g-breadcrumb bread="活动管理" /> -->
		<!-- <SearchForm style="display:flex">
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">

				<el-form-item prop="mobilePhone">
					<el-input v-model.trim="searchForm.phone" placeholder="请输入客户手机号码" />
				</el-form-item>
	
				<el-form-item>
					<el-button type="primary" @click="fetchData">搜 索</el-button>
				</el-form-item>
				
			</el-form>
			<download-excel :data="jsonData" worksheet="签到人员表格" name="签到人员表格.xls" class="excel">
                <el-button type="primary" @click="down">导出EXCEL</el-button>
            </download-excel>
		</SearchForm> -->

		<!-- <a href="http://pc.mgskin.cn/data/upload/portal/20231211/1111.pdf" target="_blank">xxx</a> -->
		
		
		<TableContainer>
			
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader" :cell-style="{ textAlign: 'center' }">
				<el-table-column prop="username" label="参会人姓名" min-width="100" />
				<el-table-column prop="sex" label="性别" min-width="100" />
				<el-table-column prop="phone" label="电话号码" min-width="100" />
				<el-table-column prop="region" label="省份" min-width="100" />
				<el-table-column prop="hospital" label="工作单位" min-width="100" />
				<el-table-column prop="unit" label="单位性质" min-width="100" />
				<el-table-column prop="duties" label="职务/职称" min-width="100" />
				<el-table-column prop="type" label="住宿要求" min-width="100" />
	
				<!-- <el-table-column prop="ycode" label="邀请码" min-width="100"/>
				<el-table-column width="200" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="deleteGarden(scope.row)">删除</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="queryBxMeigui" />
		</TableContainer>
	</section>

</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		queryBxMeigui,
        querySignature,
        delSignature,
		queryExSignature
	} from '@api/gardenManagement/api_gardenManagement'
	import {
		findMfMch,
	} from '@api/userMangement/api_userMangement'
	export default {
		data() {
			return {
				tableData: [],
				downData: [],
            jsonData: [],
				total: 0,
				// total1: 0,
				searchForm: {
					phone: "",
					page: 1,
					size: 20,
				},

			
			
			}
		},
		computed: {
			...mapState('commonModule', ['orgAreaTree'])
		},
		created() {
			this.queryBxMeigui()
		
			// this.queryExSignature()

		},

		methods: {
			
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			
			queryBxMeigui() {
				queryBxMeigui(this.searchForm).then(res => {
					this.tableData = res.records
					this.total = res.total
					// this.queryExSignature()
				})
			},
			
			queryExSignature() {
				queryExSignature(
					{
				}
				).then(res => {
					this.downData = res
				})
			},
			down() {
            this.jsonData = []
            for (var i of this.downData) {
                this.jsonData.push({
                    "姓名": i.username,
                    "电话号码": i.phone,
                    "省份": i.view,
                    "医院": i.hospital,
                    "医院类型": i.type,
                    "性别": i.sex,
                    "邀请码": i.ycode,
                })

            }
        },
     
		
			deleteGarden(row) {
				this.$confirm('是否确认删除此'+row.username+'客户?', '提示', {
					type: 'warning',
					closeOnClickModal: false
				}).then(() => {
					delSignature({id: row.id}).then(res => {
						this.$message.success('删除成功！')
						this.fetchData()
						this.queryExSignature()
					})
				})
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.reviewed {
		color: #38b9be;
	}
</style>
